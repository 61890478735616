// src/App.js
import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './pages/components/Header';
import HomePage from './pages/HomePage';

// Utilisation de React.lazy pour charger les composants uniquement lorsque nécessaire
const Overview = lazy(() => import('./pages/Overview'));
const MyServers = lazy(() => import('./pages/MyServers'));
const HistoryOfServers = lazy(() => import('./pages/HistoryOfServers'));
const Payloads = lazy(() => import('./pages/Payloads'));
const Obfuscator = lazy(() => import('./pages/Obfuscator'));
const LuaScanner = lazy(() => import('./pages/LuaScanner'));
const SmartLua = lazy(() => import('./pages/SmartLua'));
const SteamResolver = lazy(() => import('./pages/SteamResolver'));
const Rules = lazy(() => import('./pages/Rules'));
const Documentation = lazy(() => import('./pages/Documentation'));
const Members = lazy(() => import('./pages/Members'));
const Leaderboard = lazy(() => import('./pages/Leaderboard'));
const Market = lazy(() => import('./pages/Market'));
const ServerManagement = lazy(() => import('./pages/ServerManagement'));
const NotFound = lazy(() => import('./pages/NotFound'));

const App = () => {
  return (
    <Router>
      <Header />
      {/* Suspense pour afficher un fallback pendant le chargement des composants */}
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          <Route path="/" element={<HomePage />} /> {/* Route pour la page d'accueil */}
          <Route path="/overview" element={<Overview />} />
          <Route path="/my-servers" element={<MyServers />} />
          <Route path="/history-of-servers" element={<HistoryOfServers />} />
          <Route path="/payloads" element={<Payloads />} />
          <Route path="/obfuscator" element={<Obfuscator />} />
          <Route path="/lua-scanner" element={<LuaScanner />} />
          <Route path="/smart-lua" element={<SmartLua />} />
          <Route path="/steam-resolver" element={<SteamResolver />} />
          <Route path="/rules" element={<Rules />} />
          <Route path="/documentation" element={<Documentation />} />
          <Route path="/members" element={<Members />} />
          <Route path="/leaderboard" element={<Leaderboard />} />
          <Route path="/market" element={<Market />} />
          <Route path="/server-management/:serverId" element={<ServerManagement />} /> {/* Route pour la gestion des serveurs */}
          <Route path="*" element={<NotFound />} /> {/* Route pour les pages non trouvées */}
        </Routes>
      </Suspense>
    </Router>
  );
};

export default App;
