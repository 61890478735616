import React, { useState, useRef, useEffect } from 'react';
import { ChevronDown } from 'lucide-react';
import { Link } from 'react-router-dom'; // Ajout du Link pour redirection
import logo from './IVACDOOR.png';

const Header = () => {
  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownRef = useRef(null);

  // Toggle dropdown visibility
  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  // Close dropdown when clicking outside
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShowDropdown(false);
    }
  };

  // Add event listener for clicks outside dropdown
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <header className="flex justify-between items-center p-4 bg-blue-600 shadow-md">
      <div className="flex items-center">
        {/* Logo cliquable redirigeant vers la page Overview */}
        <Link to="/overview" className="logo flex items-center">
          <img src={logo} alt="IVACDOOR" className="h-12 w-auto" />
        </Link>
      </div>

      <div className="flex items-center">
        {/* User Dropdown */}
        <div className="relative" ref={dropdownRef}>
          <button
            type="button"
            className="flex items-center header-item waves-effect waves-light focus:outline-none"
            onClick={toggleDropdown}
          >
            <img
              className="rounded-circle w-8 h-8 header-profile-user"
              src="https://cdn.discordapp.com/avatars/336091550260002818/f32e117fa66d15f67b60726152b5e667"
              alt="User Avatar"
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = "/path-to-default-avatar.png"; // Fallback image if avatar fails
              }}
            />
            <span className="d-none d-sm-inline-block ml-1">ghost.dr</span>
            <ChevronDown className="d-none d-sm-inline-block ml-1" />
          </button>

          {/* Dropdown menu */}
          {showDropdown && (
            <div
              className="absolute right-0 mt-2 w-48 bg-dark-blue text-white rounded-lg shadow-lg py-2"
              style={{ top: '100%', right: '0' }}
            >
              <a href="#profile" className="block px-4 py-2 hover:bg-gray-700">
                Profile
              </a>
              <a href="#lua" className="block px-4 py-2 hover:bg-gray-700">
                Lua Code
              </a>
              <a href="#members" className="block px-4 py-2 hover:bg-gray-700">
                Online Members
              </a>
              <a href="#logout" className="block px-4 py-2 hover:bg-gray-700">
                Log Out
              </a>
            </div>
          )}
        </div>
      </div>
    </header>
  );
};

export default Header;
